import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`One of our biggest assignments to date was completed at the end of 2011. Leading
the team of  consultants, made up of Earthworks BV, Geoexploration (Nigeria)
Associates and GeoWitch of The  Netherlands, supervising the new two-million
line-kilometre airborne geophysical coverage of all  onshore Nigeria was an
assignment that extended over almost eight years. Paterson, Grant and  Watson
delivered their final interpretation products at the beginning of 2012. `}</p>
    <p>{`Copies of the data and reports can be purchased from the Nigerian Geological
Survey Agency in  Abuja (`}<a parentName="p" {...{
        "href": "http://www.mmsd.gov.ng/"
      }}>{`http://www.mmsd.gov.ng/`}</a>{`). The work is part of the World
Bank's Sustainable  Management of Mineral Resources Project, designed to give
new impetus and direction to  commercial exploration for solid minerals in the
country and re-establish after half a century a  significant role for indigenous
minerals in Nigeria's economy. An idea of the new level of detail  afforded to
the geological map of the country may be gained from the ternary image of the
gamma-ray spectrometry undertaken during the airborne survey, reproduced below.
Many other  examples can be found on the geophysical data portal of the Ministry
of Mines and Steel  Development mentioned above. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      